import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Table from "./pages/Table";
import Graph from "./pages/Graph";
import AddProducts from "./pages/AddProducts";
import Errorpage from "./pages/Errorpage";
import "./app.css";
import ErrorLog from "./pages/ErrorLog";
import AliasAccounts from "./pages/AliasAccounts";

function App() {
    return (
        <>
            <h2 className="notice">You can only view on a larger screen</h2>

            <div className="app">
                <Router>
                    <section className="right">
                        <Navbar />
                    </section>
                    <section className="left">
                        <Routes>
                            <Route path="/" element={<Table />}></Route>
                            <Route path="/plot" element={<Graph />}></Route>
                            <Route path="/alias-accounts" element={<AliasAccounts />}></Route>
                            <Route path="/error-logs" element={<ErrorLog />}></Route>
                            <Route path="/add-products" element={<AddProducts />}></Route>
                            <Route path="*" element={<Errorpage />}></Route>
                        </Routes>
                    </section>
                </Router>
            </div>
        </>
    );
}

export default App;
