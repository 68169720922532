import { useRef, useState } from "react";
import { FaTrash } from "react-icons/fa/"
import { useGlobalContext } from "../context";
import "../pages/styles/errorpage.css";
import "../pages/styles/addproducts.css";
import axios from "axios";
import Toast from "./Toast";

const AboutSneaker = () => {
    const [readMore, setreadMore] = useState(false);
    const { sneakerItem, sizeSelected, setSneakerItem, setSneakerSales, setTotalSales, products, setProducts } = useGlobalContext();
    const [scrape_rate, setScrapeRate] = useState(sneakerItem?.ScrapeRate || 0);
    const inputRef = useRef(null);
    const [isToast, setisToast] = useState({
        open: false,
        message: "",
        color: "",
    });

    const closeToast = () => setisToast({ ...isToast, open: false });
    const handleKeyDown = async (event) => {
        event.preventDefault();
        setisToast({
            message: `Editing crawl interval on ${sneakerItem.Title}`,
            open: true,
            color: "pending",
        });

        if (inputRef.current.value.trim() !== '') {
            try {
                const number = parseInt(inputRef.current.value.trim(), 10);
                if (number >= 1 && number <= 24) {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`, {
                        'query': `mutation {\n  EditCrawlInterval(interval: ${number}, sku: "${sneakerItem.Sku}")\n}`
                    },
                        {
                        }
                    );
                    setScrapeRate(data.data.EditCrawlInterval)
                } else {
                    console.error('Please enter a number between 1 and 24.');
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const deleteItem = async (sku) => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed_delete = confirm(`Delete ${sneakerItem.Title} and all sales records from database?`)
        switch (confirmed_delete) {
            case true:
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                    {
                        'query': `query {\n  DeleteSneaker(sku: "${sku}")\n}`
                    }
                );

                if (data.data) {
                    setisToast({
                        message: `${sneakerItem.Title} deleted from database!`,
                        open: true,
                        color: "success",
                    });
                    setProducts(products.filter(x => x.Sku !== sneakerItem.Sku))
                    setSneakerItem({})
                    setSneakerSales([])
                    setTotalSales(0)
                    setScrapeRate(0)
                    return
                }

                setisToast({
                    message: data?.errors?.map(error => error.message)?.toString(),
                    open: true,
                    color: "danger",
                });
                return
            default:
                return
        }
    }

    if (sneakerItem) {
        const { Title, Description, Image, ReleaseDate, Color, Price, Link, Style, Tags } =
            sneakerItem;

        const newDescription = Description?.replaceAll("<br>", "\n");


        return (
            <>
                <div className="image-continer">
                    <img src={Image} alt={Title} />
                </div>
                <div className="description">
                    <h2>{Title}

                    </h2>
                    <FaTrash
                        className="delete-btn"
                        onClick={() => deleteItem(sneakerItem.Sku)}
                    />

                    <hr />
                    <p>
                        {readMore
                            ? newDescription
                            : newDescription?.substring(0, 250) + "..."}
                        <br />
                        <span onClick={() => setreadMore(!readMore)}>
                            {newDescription?.length > 250
                                ? readMore
                                    ? "Show less"
                                    : "Show more"
                                : null}
                        </span>
                    </p>
                    <p>
                        <strong>Color:</strong> {Color}
                    </p>
                    <p>
                        <strong>Retail Price:</strong> ${Price}
                    </p>
                    <p>
                        <strong>Style:</strong> <b>{Style}</b>
                    </p>
                    <p>
                        <strong>Release date:</strong> {ReleaseDate}
                    </p>
                    <p>
                        <strong>Scrape per Day:</strong> {scrape_rate}
                    </p>
                    <p>
                        <a
                            href={sizeSelected ? `${Link}?size=${sizeSelected}` : Link}
                            target="_blank"
                            rel="noreferrer"
                            className="about-sneaker-link"
                        >
                            Click to visit on Site
                        </a>
                    </p>
                    <p>
                        <strong>tags:</strong>
                        <b><i></i> {Tags?.join(",")}</b>
                    </p>

                    {isToast.open && (
                        <Toast
                            closeToast={closeToast}
                            message={isToast.message}
                            color={isToast.color}
                        />
                    )}

                    <form className="add-products-form" onSubmit={handleKeyDown}>
                        <input
                            type="text"
                            placeholder="edit scrape frequency"
                            ref={inputRef}
                        />
                    </form>
                </div>
            </>
        );
    }
};

export default AboutSneaker;
