import { useEffect } from "react";

const Toast = ({ closeToast, message, color }) => {
    useEffect(() => {
        const hideToast = setTimeout(() => {
            closeToast();
        }, 3000);

        return () => clearTimeout(hideToast);
    });

    return <p className={`toast ${color}`}>{message}</p>;
};

export default Toast;
