import React from "react";
import TableChartIcon from "@mui/icons-material/TableChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from "react-router-dom";
import "./styles/navbar.css";

const Navbar = () => {
    return (
        <aside className="navbar">
            <div className="nav-btn-area">
                <Link to="/">
                    <button className="navbar-btn">
                        <TableChartIcon className="nav-btn-icon" />
                    </button>
                </Link>
                <Link to="/plot">
                    <button className="navbar-btn">
                        <SsidChartIcon className="nav-btn-icon" />
                    </button>
                </Link>
                <Link to="/alias-accounts">
                    <button className="navbar-btn">
                        <TableChartIcon className="nav-btn-icon" />
                    </button>
                </Link>
                <Link to="/error-logs">
                    <button className="navbar-btn">
                        <ErrorIcon className="nav-btn-icon" />
                    </button>
                </Link>
                <Link to="/add-products">
                    <button className="navbar-btn">
                        <AddIcon className="nav-btn-icon" />
                    </button>
                </Link>
            </div>
        </aside>
    );
};

export default Navbar;
