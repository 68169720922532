import Nothing from "../components/Nothing";
import { useGlobalContext } from "../context";
import Loader from "../components/Loader.js";
import { v4 as uuidv4 } from "uuid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./styles/table.css";

const ErrorLog = () => {
    const {
        nothingToSee,
        loading,
        error_logs,
        error_offset,
        set_error_offset
    } = useGlobalContext();

    // useEffect(() => {
    //     set_error_offset(0)
    // });

    const next = () => {
        if (error_logs.length === 0) {
            set_error_offset(0);
        } else {
            set_error_offset(error_offset + 10)
        };
    };

    const prev = () => {
        if (error_offset < 1) {
            set_error_offset(0);
        } else {
            set_error_offset(error_offset - 10);
        }
    };

    return (
        <div className="table">
            <main className="content-region">
                {loading ? (
                    <Loader />
                ) : nothingToSee ? (
                    <Nothing />
                ) : (
                    <>
                        {error_logs && (
                            <section className="table-container">
                                <h1>Error Logs</h1>
                                <table className="content-table">
                                    <thead>
                                        <tr>
                                            <th>s/n</th>
                                            <th>Date</th>
                                            <th>Root</th>
                                            <th>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {error_logs.map((error_log, idx) => {
                                            const { Root, Message, CreatedAt } = error_log;
                                            return (
                                                <tr key={uuidv4()}>
                                                    <td>{(idx + 1) + error_offset}</td>
                                                    <td className="break-text">{CreatedAt}</td>
                                                    <td className="break-text">{Root}</td>
                                                    <td className="break-text">{Message}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {error_logs.length === 0 && (
                                    <div className="no-fetch">
                                        <ErrorOutlineIcon
                                            style={{
                                                fontSize: "3rem",
                                            }}
                                        />
                                        <h1>Nothing to see here</h1>
                                    </div>
                                )}
                                <div className="btn-container">
                                    {error_offset > 0 && (
                                        <button onClick={prev}>Prev</button>
                                    )}

                                    {error_logs.length === 10 && (
                                        <button onClick={next}>Next</button>
                                    )}

                                </div>
                            </section>
                        )}
                    </>
                )}
            </main>
        </div>
    );
};

export default ErrorLog;
