import { useEffect, useRef, useState } from "react";
import "./styles/dropdown.css";
import { useGlobalContext } from "../context";

const SearchableDropdown = ({
    options,
    label,
    id,
    selectedVal,
    handleChange,
}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const { setSkuSelected, setSizeSelected, setOffsetVal } = useGlobalContext()

    const inputRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    // sort sizes
    if (id === "size") {
        options = options
            ?.sort(function (a, b) {
                const aSize = parseFloat(a);
                const bSize = parseFloat(b);
                const aLetter = a.replace(/[\d\.]/g, '');
                const bLetter = b.replace(/[\d\.]/g, '');

                if (aSize < bSize) {
                    return -1;
                } else if (aSize > bSize) {
                    return 1;
                } else {
                    // if the sizes are equal, compare the letters
                    if (aLetter < bLetter) {
                        return -1;
                    } else if (aLetter > bLetter) {
                        return 1;
                    } else {
                        // if the letters are also equal, keep their relative order
                        return 0;
                    }
                }
            }).map(val => ({ Title: val }))
    }

    const selectOption = (option) => {
        setQuery(() => "");
        handleChange(option[label]);
        setIsOpen((isOpen) => !isOpen);

        switch (id) {
            case "sneaker":
                setSkuSelected(option.Sku)
                break;
            case "size":
                setSizeSelected(option.Title)
                setOffsetVal(0);
                break;
            default:
                break
        }
    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }

    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal;

        return "";
    };

    const filter = (options) => {
        return options?.filter(
            (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    return (
        <div className="dropdown">
            <div className="control">
                <div className="selected-value">
                    <input
                        ref={inputRef}
                        type="text"
                        value={getDisplayValue()}
                        name="searchTerm"
                        autoComplete="off"
                        onChange={(e) => {
                            setQuery(e.target.value);
                            handleChange(null);
                        }}
                        onClick={toggle}
                    />
                </div>
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>

            <div className={`options ${isOpen ? "open" : ""}`}>
                {filter(options)?.map((option, index) => {
                    return (
                        <div
                            onClick={() => selectOption(option)}
                            className={`option ${option[label] === selectedVal ? "selected" : ""
                                }`}
                            key={`${id}-${index}`}
                        >
                            {option[label]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SearchableDropdown;
