const formatDate = (datestring) => {
  const date = new Date(datestring);
  return date.toLocaleString({
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export default formatDate;

export const formatDateEng = (datestring) => {
  const date = new Date(datestring);
  return date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
