import { useEffect, useState } from "react";
import AboutSneaker from "../components/AboutSneaker";
import Nothing from "../components/Nothing";
import { useGlobalContext } from "../context";
import Loader from "../components/Loader.js";
import { v4 as uuidv4 } from "uuid";
import formatDate from "../utils/formatDate";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./styles/table.css";
import SearchableDropdown from "../components/SearchableDropDown";

const Table = () => {
    const {
        nothingToSee,
        loading,
        sneakerSales,
        setOffsetVal,
        offsetVal,
        setlimit,
        totalSales,
        sizes, products
    } = useGlobalContext();
    const [sneakerValue, setSneakerValue] = useState("Select sneaker...");
    const [sizeValue, setSizeValue] = useState("Select size...");


    useEffect(() => {
        setlimit(20);
    });

    const next = () => {
        if (sneakerSales.length === 0) {
            setOffsetVal(0);
        } else setOffsetVal(offsetVal + 20);
    };

    const prev = () => {
        if (offsetVal < 1) {
            setOffsetVal(0);
        } else {
            setOffsetVal(offsetVal - 20);
        }
    };

    return (
        <div className="table">
            <div className="dropdown-container">
                <div className="dropdown-each">
                    <SearchableDropdown
                        options={products}
                        label="Title"
                        id="sneaker"
                        selectedVal={sneakerValue}
                        handleChange={(val) => setSneakerValue(val)}
                    />
                </div>
                <div className="dropdown-each">
                    <SearchableDropdown
                        options={sizes}
                        label="Title"
                        id="size"
                        selectedVal={sizeValue}
                        handleChange={(val) => setSizeValue(val)}
                    />
                </div>
            </div>

            <main className="content-region">
                {loading ? (
                    <Loader />
                ) : nothingToSee ? (
                    <Nothing />
                ) : (
                    <>
                        <section className="about-sneaker">
                            <AboutSneaker />
                        </section>
                        {sneakerSales && (
                            <section className="table-container">
                                <h1>All Sales({totalSales})</h1>
                                <table className="content-table">
                                    <thead>
                                        <tr>
                                            <th>s/n</th>
                                            <th>Date</th>
                                            <th>Size</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sneakerSales.map((eachSale, idx) => {
                                            const { SaleDate, SalePrice, SaleSize } = eachSale;
                                            return (
                                                <tr key={uuidv4()}>
                                                    <td>{(idx + 1) + offsetVal}</td>
                                                    <td>{formatDate(SaleDate)}</td>
                                                    <td>{SaleSize}</td>
                                                    <td>${Math.round(SalePrice)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {sneakerSales.length === 0 && (
                                    <div className="no-fetch">
                                        <ErrorOutlineIcon
                                            style={{
                                                fontSize: "3rem",
                                            }}
                                        />
                                        <h1>Nothing to see here</h1>
                                    </div>
                                )}
                                <div className="btn-container">
                                    {offsetVal > 0 && (
                                        <button onClick={prev}>Prev</button>
                                    )}

                                    {sneakerSales.length === 20 && (
                                        <button onClick={next}>Next</button>
                                    )}

                                </div>
                            </section>
                        )}
                    </>
                )}
            </main>
        </div>
    );
};

export default Table;
