import Nothing from "../components/Nothing";
import AreaPlot from "../components/AreaPlot";
import AboutSneaker from "../components/AboutSneaker";
import Loader from "../components/Loader.js";
import { useGlobalContext } from "../context";
import "./styles/graph.css";
import { useState } from "react";
import SearchableDropdown from "../components/SearchableDropDown";

const Graph = () => {
    const { nothingToSee, loading, products, sneakers, sizes } = useGlobalContext();
    const [sneakerValue, setSneakerValue] = useState("Select sneaker...");
    const [sizeValue, setSizeValue] = useState("Select size...");


    return (
        <div className="graph">
            <div className="dropdown-container">
                <div className="dropdown-each">
                    <SearchableDropdown
                        options={products}
                        label="Title"
                        id="sneaker"
                        selectedVal={sneakerValue}
                        handleChange={(val) => setSneakerValue(val)}
                    />
                </div>
                <div className="dropdown-each">
                    <SearchableDropdown
                        options={sizes}
                        label="Title"
                        id="size"
                        selectedVal={sizeValue}
                        handleChange={(val) => setSizeValue(val)}
                    />
                </div>
            </div>

            <main className="content-region">
                {loading ? (
                    <Loader />
                ) : nothingToSee ? (
                    <Nothing />
                ) : (
                    <>
                        <section className="about-sneaker">
                            <AboutSneaker />
                        </section>

                        <section className="plot-graph">
                            <AreaPlot />
                        </section>
                    </>
                )}
            </main>
        </div>
    );
};

export default Graph;
