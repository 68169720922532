import { useRef, useEffect, useState } from "react";
import Toast from "../components/Toast";
import AttachmentIcon from "@mui/icons-material/Attachment";
import axios from "axios";
import "./styles/errorpage.css";
import "./styles/addproducts.css";
import { useGlobalContext } from "../context";
import SearchableDropdown from "../components/SearchableDropDown";



const AddProducts = () => {
    const inputRef = useRef(null);
    const [isToast, setisToast] = useState({
        open: false,
        message: "",
        color: "",
    });
    const tags = [
        {
            "Key": "stockx",
            "Title": "stockx",
        }, {
            "Key": "alias",
            "Title": "alias",
        }
    ]
    const { products, setProducts } = useGlobalContext()
    const [tag, setTag] = useState(null)

    useEffect(() => {
        inputRef.current.focus();
    });

    const handleSubmit = async (e) => {

        if (!tag || !inputRef.current.value) {
            setisToast({
                message: "Please select site tag and fill in term!",
                open: true,
                color: "danger",
            });
            return
        }

        e.preventDefault();

        const slug = inputRef.current.value.replace(/\s+/g, " ").trim()
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                {
                    'query': `mutation{\n  CreateProduct(slug: "${slug}", site_tags: ["${tag}"]) {\n    Sku,\n    Title,\n    Link,\n    Description,\n    Price,\n    Style,\n    ReleaseDate,\n    Color,\n    Image,\n    Key\n  }\n}\n`
                }
            );

            if (!data.data) {
                throw new Error(data?.errors?.map(error => error.message)?.toString())
            }

            setisToast({
                message: `${data.data.CreateProduct.Title} added to database!`,
                open: true,
                color: "success",
            });
            setProducts([{
                Sku: data.data.CreateProduct.Sku,
                Title: data.data.CreateProduct.Title
            }, ...products])

        } catch (error) {
            setisToast({
                message: `Failed to scrape slug ${slug}: ${error.message}`,
                open: true,
                color: "danger",
            });
        }

        inputRef.current.value = "";
    };

    const closeToast = () => setisToast({ ...isToast, open: false });

    const submitProxy = (e) => {
        let file = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(file[0]);

        setisToast({
            message: `Uploading Proxies...`,
            open: true,
            color: "pending",
        });

        reader.onload = async (e) => {
            try {
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                    {
                        query: `mutation {\n  UploadProxy(proxy:"${e.target.result?.replace('data:text/plain;base64,', '')}") {\n    Uploaded\n  }\n}`,
                    },
                    {
                        headers: {
                            "content-type": "application/json",
                        },
                    }
                );

                if (data?.data?.UploadProxy?.Uploaded) {
                    setisToast({
                        message: `Proxy set uploaded`,
                        open: true,
                        color: "success",
                    });
                } else {
                    setisToast({
                        message: `Proxy set not uploaded!`,
                        open: true,
                        color: "danger",
                    });
                }
            } catch (e) {
                setisToast({
                    message: `Proxy set not uploaded!\nReason: ${e.message}`,
                    open: true,
                    color: "danger",
                });
            }
        };
    };

    return (
        <div className="errorpage">
            <div className="add-products-box">
                {isToast.open && (
                    <Toast
                        closeToast={closeToast}
                        message={isToast.message}
                        color={isToast.color}
                    />
                )}

                <form className="add-products-form" onSubmit={handleSubmit}>
                    <div className="dropdown-each">
                        <SearchableDropdown
                            options={tags}
                            label="Title"
                            id="site-tag"
                            selectedVal={tag}
                            handleChange={(val) => setTag(val)}
                        />
                    </div>
                    <input
                        type="text"
                        placeholder="air-jordan-2-retro-union-grey-fog"
                        ref={inputRef}
                    />
                    <button type="submit">Add</button>
                </form>

                <div className="file-uploader">
                    <input
                        type="file"
                        id="upload"
                        style={{ display: "none" }}
                        accept=".txt"
                        onChange={(e) => submitProxy(e)}
                    />

                    <label htmlFor="upload" className="button">
                        <AttachmentIcon />
                        Choose .txt file to upload
                    </label>
                </div>
            </div >
        </div >
    );
};

export default AddProducts;
