import React, { useEffect } from 'react';
import CanvasJSReact from '../canvasjs.react';
import { useGlobalContext } from '../context';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const HighChart = () => {
    const { sneakerSales, setlimit } = useGlobalContext();

    useEffect(() => {
        setlimit(-1);
    });

    if (sneakerSales) {
        let data = [];
        let dataSeries = {
            type: "area",
            dataPoints: []
        };
        let i = 0, len_data = sneakerSales?.length

        while (i < len_data) {
            dataSeries.dataPoints.push({
                x: new Date(sneakerSales[i].SaleDate),
                y: Math.round(sneakerSales[i].SalePrice),
                toolTipContent: `size: ${sneakerSales[i].SaleSize}<br/>price: ${sneakerSales[i].SalePrice}<br/>date: ${sneakerSales[i].SaleDate}`
            });
            i++
        }

        data = [dataSeries]

        const spanStyle = {
            position: 'absolute',
            top: '10px',
            fontSize: '20px',
            fontWeight: 'bold',
            backgroundColor: '#d85757',
            padding: '0px 4px',
            color: '#ffffff'
        }

        const options = {
            zoomEnabled: true,
            animationEnabled: true,
            title: {
                text: "Sales Graph"
            },
            data: data
        }

        return (
            <div>
                <CanvasJSChart options={options}
                />
                <span id="timeToRender" style={spanStyle}></span>
            </div>
        );
    }
}

export default HighChart;