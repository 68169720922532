import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./styles/nothing.css";

const Nothing = () => {
  return (
    <div className="nothing">
      <ErrorOutlineIcon
        style={{
          fontSize: "4rem",
        }}
      />
      <h1>Nothing to see here</h1>
    </div>
  );
};

export default Nothing;
