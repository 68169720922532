import React from "react";
import { Link } from "react-router-dom";
import "./styles/errorpage.css";

const Errorpage = () => {
  return (
    <div className="errorpage">
      <div className="error-box">
        <div className="contant_box_404"></div>
        <h1>Looks like you're lost</h1>
        <p>The page you're looking for is not available</p>
        <Link to="/">
          <button>Go Home</button>
        </Link>
      </div>
    </div>
  );
};

export default Errorpage;
