import "./styles/loader.css";

const Loader = () => {
  return (
    <main>
      <div className="loading">
        <div className="spinner"></div>
      </div>
    </main>
  );
};

export default Loader;
