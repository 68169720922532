import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
// import { config } from "dotenv"

// config()
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [nothingToSee, setNothingToSee] = useState(true);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [unmodifiedProducts, setUnmodifiedProducts] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [skuSelected, setSkuSelected] = useState("");
    const [sneakerItem, setSneakerItem] = useState({});
    const [sneakerSales, setSneakerSales] = useState([]);
    const [error_logs, set_error_logs] = useState([]);
    const [error_offset, set_error_offset] = useState(0);
    const [offsetVal, setOffsetVal] = useState(0);
    const [limit, setlimit] = useState(20);
    const [totalSales, setTotalSales] = useState(0);
    const [sizeSelected, setSizeSelected] = useState(null);

    // get default products for display
    const getProducts = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                {
                    'query': 'query {\n  LoadProductPreflight {\n    Sneakers {\n      Sku,\n      Title\n    }\n  }\n}'
                }
            );
            if (!data?.data) {
                throw new Error(data?.errors?.map(error => error.message)?.toString())
            }
            setProducts(data.data.LoadProductPreflight.Sneakers);
            setUnmodifiedProducts(data.data.LoadProductPreflight.Sneakers);
            setLoading(false);
        } catch (error) {
            setNothingToSee(true);
            alert(error.message);
        }
    };

    const getProductSizes = async (sku) => {
        try {
            const { data: { data: { LoadSizes: { Sizes } } } } = await axios.post(
                `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                {
                    'query': `query {\n  LoadSizes(sku: "${sku}") {\n    \tSizes\n  }\n}`
                }
            );
            return Sizes
        } catch (error) {
            alert(error.message);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    // fetch error logs
    const get_error_logs = useCallback(
        async () => {
            let page = (error_offset / 10) + 1
            let query =
                `query {\n  GetErrorLogs(page:${page}){\n    Data{\n      Root,\n      Message,\n      CreatedAt\n    },\n    Pagination{\n      Page,\n      NextPage\n    }\n  }\n}`

            try {
                setLoading(true);
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`, {
                    query: query,
                });

                setLoading(false);
                if (!data.data) {
                    setNothingToSee(true);
                    throw new Error(data?.errors?.map(error => error.message)?.toString());
                }
                setNothingToSee(false)
                return data.data.GetErrorLogs.Data;
            } catch (err) {
                setNothingToSee(true);
                alert(err.message);
            }
        },
        [error_offset]
    )

    // fetch sneaker info
    const fetchSneaker = useCallback(
        async (sku) => {
            if (skuSelected) {
                try {

                    const [{ data }, sizes] = await Promise.all([
                        axios.post(
                            `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`,
                            {
                                'query': `query {\n  GetSneaker(sku: "${sku}") {\n    Sku,\n    Title,\n    Link,\n    Description,\n    Price,\n    Style,\n    ReleaseDate,\n    Color,\n    Image,\n    ScrapeRate,\n    Key\n,\n    Tags\n  }\n}`
                            },
                        ),
                        getProductSizes(skuSelected)
                    ])

                    if (!data.data) {
                        throw new Error(data?.errors?.map(error => error.message)?.toString())
                    }
                    setSizes(sizes)

                    setLoading(false);
                    setNothingToSee(false);
                    return data.data.GetSneaker
                } catch (err) {
                    // write a custom error handler
                    setNothingToSee(true);
                    alert(err.message);
                }
            }
        },
        [skuSelected]
    );

    // fetch sale
    const fetchSale = useCallback(
        async (args) => {
            let page = 1
            if (args.offset) {
                page = (args.offset / 20) + 1
            }
            if (limit === -1) page = -1

            let query =
                `query {\n  GetSales(page:${page}, sku: "${args.sku}", size:"") {\n    Data {\n      Uuid,\n      SaleDate,\n      SalePrice,\n      SaleSize\n    }\n    Pagination{\n      Page,\n      NextPage,\n      TotalCount\n    }\n  }\n}`

            if (args.size) {
                query = `query {\n  GetSales(page:${page}, sku: "${args.sku}", size:"${args.size}") {\n    Data {\n      Uuid,\n      SaleDate,\n      SalePrice,\n      SaleSize\n    }\n    Pagination{\n      Page,\n      NextPage,\n      TotalCount\n    }\n  }\n}`;
            }

            if (skuSelected) {
                try {
                    setLoading(true);
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_BASEHOST || ""}/graphql/query`, {
                        query: query,
                    });

                    setLoading(false);
                    if (!data.data) {
                        setNothingToSee(true);
                        throw new Error(data?.errors?.map(error => error.message)?.toString());
                    }
                    setTotalSales(data.data.GetSales.Pagination.TotalCount)
                    return data.data.GetSales.Data;
                } catch (err) {
                    setNothingToSee(true);
                    alert(err.message);
                }
            }
        },
        [skuSelected, limit]
    );

    const getSneakerWithouthSize = useCallback(async () => {
        setOffsetVal(0);
        const sneaker = await fetchSneaker(skuSelected);

        setSneakerItem(sneaker);
    }, [skuSelected, fetchSneaker]);

    useEffect(() => {
        getSneakerWithouthSize();
    }, [getSneakerWithouthSize, skuSelected]);

    const pagination = useCallback(async () => {
        const sales = await fetchSale({
            sku: skuSelected,
            size: sizeSelected,
            offset: offsetVal,
        });
        setSneakerSales(sales);
    }, [offsetVal, fetchSale, sizeSelected, skuSelected]);

    useEffect(() => {
        pagination();
    }, [offsetVal, pagination]);

    const error_pagination = useCallback(async () => {
        const _logs = await get_error_logs(error_offset)
        set_error_logs(_logs);
    }, [error_offset, set_error_logs, get_error_logs])

    useEffect(() => {
        error_pagination();
    }, [offsetVal, error_pagination]);

    return (
        <AppContext.Provider
            value={{
                nothingToSee,
                loading,
                products,
                setProducts,
                unmodifiedProducts,
                setSkuSelected,
                sneakerItem,
                setSneakerItem,
                sneakerSales,
                setOffsetVal,
                setlimit,
                offsetVal,
                setSizeSelected,
                sizeSelected,
                error_logs,
                set_error_logs,
                error_offset,
                sizes,
                set_error_offset,
                totalSales,
                setTotalSales,
                setSneakerSales
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppProvider };
